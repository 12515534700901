import Spinner from './Spinner'

import styles from 'styles/SpinnerBlock.module.css'

const SpinnerBlock = ({ height = '100%', ...rest }) => (
    <div className={styles.block} style={{ height }}>
        <Spinner {...rest} />
    </div>
)

export default SpinnerBlock