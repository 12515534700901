const colors = [
    'Beżowy',
    'Biały',
    'Bordowy',
    'Brązowy',
    'Czarny',
    'Czerwony',
    'Fioletowy',
    'Granatowy',
    'Metaliczny',
    'Niebieski',
    'Pomarańczowy',
    'Srebrny',
    'Szary',
    'Zielony',
    'Złoty lub żółty',
    'Inny',
    'Wszystkie',
]

export const colorNames = [
    'kolor-bezowy',
    'kolor-bialy',
    'kolor-bordowy',
    'kolor-brazowy',
    'kolor-czarny',
    'kolor-czerwony',
    'kolor-fioletowy',
    'kolor-granatowy',
    'kolor-metaliczny',
    'kolor-niebieski',
    'kolor-pomaranczowy',
    'kolor-srebrny',
    'kolor-szary',
    'kolor-zielony',
    'kolor-zolty-lub-zloty',
    'kolor-inny',
    'kolor-wszystkie',
]

export default colors