import createSlug from '../slug/createSlug'

// do not use new ES syntax here:
const offerSlug = (offer) => {
    const { part_name, title, make, model, data = {}, tracking = {}} = offer || {}

    const city = tracking && tracking.city

    let slugComponents

    const isMakeModelBased = Boolean(make)
    if (isMakeModelBased) {
        // make, model, type and city
        const type = data && data.body_type || data && data.truck_body_type
        slugComponents = [make, model, type, city]
    } else {
        // name or title and city
        const name = part_name || title
        slugComponents = [name, city]
    }

    return createSlug(
        slugComponents
            .filter(Boolean)
            .join('-'))
}

export default offerSlug