const carSafety = [
	'Alarm',
	'ASR (kontrola trakcji)',
	'Asystent pasa ruchu',
	'Czujnik martwego pola',
	'ESP (stabilizacja toru jazdy)',
	'Immobilizer',
	'Isofix',
	'Kurtyny powietrzne',
	'Poduszka powietrzna chroniąca kolana',
	'Poduszka powietrzna kierowcy',
	'Poduszka powietrzna pasażera',
	'Poduszki boczne przednie',
	'Poduszki boczne tylne',
	'System awaryjnego hamowania',
	'Wspomaganie kierownicy',
]

const carComfort = [
    'Alufelgi',
	'Asystent parkowania',
	'Bluetooth',
	'Centralny zamek',
	'Czujnik deszczu',
	'Czujnik zmierzchu',
	'Czujniki parkowania przednie',
	'Czujniki parkowania tylne',
	'Dach panoramiczny',
	'Elektrochromatyczne lusterka boczne',
	'Elektrochromatyczne lusterko wsteczne',
	'Elektryczne szyby przednie',
	'Elektryczne szyby tylne',
	'Elektrycznie ustawiane fotele',
	'Elektrycznie ustawiane lusterka',
	'Gniazdo AUX',
	'Gniazdo SD',
	'Gniazdo USB',
	'Hak',
	'HUD (wyświetlacz przezierny)',
	'Kamera cofania',
	'Klimatyzacja automatyczna',
	'Klimatyzacja manualna',
	'Komputer pokładowy',
	'Łopatki zmiany biegów',
	'MP3',
	'Nawigacja GPS',
	'Odtwarzacz DVD',
	'Ogranicznik prędkości',
	'Ogrzewanie postojowe',
	'Podgrzewana przednia szyba',
	'Podgrzewane lusterka boczne',
	'Podgrzewane przednie siedzenia',
	'Podgrzewane tylne siedzenia',
	'Przyciemniane szyby',
	'Radio fabryczne',
	'Radio niefabryczne',
	'Regulowane zawieszenie',
	'Relingi dachowe',
	'System Start-Stop',
	'Szyberdach',
	'Światła do jazdy dziennej',
	'Światła LED',
	'Światła przeciwmgielne',
	'Światła Xenonowe',
	'Tapicerka skórzana',
	'Tapicerka welurowa',
	'Tempomat',
	'Tempomat aktywny',
	'Tuner TV',
	'Wentylowane fotele',
	'Wielofunkcyjna kierownica',
	'Zmieniarka CD',
]

export default [...carSafety, ...carComfort]
export {
    carSafety,
    carComfort
}