import styles from 'styles/FeaturedInfoTag.module.css'
import format from '@utils/price/format'

const FeaturedInfoTag = ({ name, price, currency, info }) => (
    <div className={styles.tag}>
        <h3 className={styles.heading}>{name}</h3>
        <div className={styles.extra}>
            <div className={styles.price}>{format(price)} {currency}</div>
            <div className={styles.info}>{info}</div>
        </div>
    </div>
)

export default FeaturedInfoTag