const typeAnex = (type, space = ' - ') => {
    const anex = {
        car_ad: 'samochody osobowe',
        truck_ad: 'samochódy dostawcze',
        motorcycle_ad: 'motocykle',
        tool_ad: 'narzędzia warsztatowe',
        part_ad: 'części samochodowe',
        service_ad: 'serwisy samochodowe i usługi',
        agri_ad: 'maszyny rolnicze',
        budowlane: 'maszyny budowlane',
        przyczepy: 'przyczepy',
    }[type] || ''

    return anex
        ? space + anex
        : ''
}

export default typeAnex