const gearboxes = [
    'Manualna',
    'Automatyczna',
]

export const gearboxNames = [
    'skrzynia-biegow-manualna',
    'skrzynia-biegow-automatyczna',
]

export default gearboxes