const lacquers = [
    'Akryl',
    'Perłowy',
    'Matowy',
    'Metalik',
]

export const lacquerNames = [
    'lakier-akrylowy',
    'lakier-perlowy',
    'lakier-matowy',
    'lakier-metalik',
]

export default lacquers