import { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import useT from '@hooks/useTranslation'
import useWindowWidth from '@hooks/useWindowWidth'

import styles from 'styles/ChangingAdBaner.module.css'

const ChangingAdBanner = ({ data, time = 10000, style = { height: 200, margin: '30px auto' }}) => {
    const [active, setActive] = useState(0)
    const width = useWindowWidth()
    const onMobile = width <= 1024

    useEffect(() => {
        if (data.length < 2) return
        const id = setTimeout(
            () => setActive((active + 1) % data.length),
            time
        )

        return () => clearTimeout(id)
    }, [active])

    if (data.length < 1) return <Empty style={style} />

    const { link, photo, mobilePhoto, project } = data[active]

    return (
        <Link href={link}>
            <a target="_blank" rel="noopener noreferrer">
                <div className={styles.wrapper} style={style}>
                    <Image
                        src={onMobile
                            ? mobilePhoto
                            : photo}
                        alt={project}
                        layout="fill"
                        objectFit="cover" />
                </div>
            </a>
        </Link>
    )
}

function Empty ({ style = {} }) {
    return (
        <div className={styles.wrapper} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...style }}>
            <p style={{ fontSize: '30rem', fontWeight: 'bold', color: 'var(--input-border-c)', textAlign: 'center', padding: '0 20px' }}>{useT('Miejsce na Twoją reklamę')}</p>
        </div>
    )
}

export default ChangingAdBanner