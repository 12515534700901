// import { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from '@components/image/WithFallback'
import FeaturedInfoTag from './FeaturedInfoTag'
import ListOut from '@components/shared/ListOut'

import coverPhoto from '@utils/links/coverPhoto'
import offerLink from '@utils/links/offerLink'
import offerName from '@utils/infoPresentation/offerName'
import offerInfo from '@utils/infoPresentation/offerInfo'
import typeAnex from '@utils/infoPresentation/typeAnex'
import random from '@utils/random/random'

import { LOGIN_PAGE } from '@constants/routes'

import styles from 'styles/ImageWrapper.module.css'

const RandomStub = () => {
    const picNum = random(14)
    const src = `/carpics/car_${String(picNum).padStart(2, '0')}.jpeg`
    const name = 'Mejsce na twoje ogłoszenie!'
    const price = random(999) * 1000
    const currency = 'PLN'
    const info = 'Wypromuj | Zarabiaj'

    return <SlideImage {...{
        src,
        alt: name,
        wrapperClass: styles.active,
        infoProps: { name, price, currency, info }
    }} />
}

const Slide = (props) => {
    if (!props.price || !props.tracking) return null

    const { price: { price, currency }, tracking: { pid },
        type, active, i } = props

    const name = offerName(props)
    const src = coverPhoto(pid)
    const anex = typeAnex(type)
    const isActive = active == null
        ? props.className
        : active === i
            ? styles.active
            : styles.inactive

    const link = offerLink(props)
    return (
        <SlideImage {...{
            src,
            link,
            alt: name + anex,
            wrapperClass: isActive,
            infoProps: isActive
                ? { name, price, currency, info: offerInfo(props) }
                : undefined
        }} />
    )

}

const SlideImage = ({ src, link, alt, wrapperClass, infoProps }) => (
    <div className={`${styles.wrapper} ${wrapperClass}`}>
        <Link href={link || LOGIN_PAGE} prefetch={false}>
            <a>
                <Image
                    src={src}
                    alt={alt}
                    layout="fill"
                    objectFit="cover"
                    priority="true"
                    fallback="/fallback/list.png"
                />

                { infoProps && <FeaturedInfoTag {...infoProps} /> }
            </a>
        </Link>
    </div>
)

const FeaturedSlider = ({ items }) => { // time = 7000
    // const [active, setActive] = useState(0)

    // useEffect(() => {
    //     if (items.length <= 1) return
    //     const id = setTimeout(() => {
    //         setActive((active + 1) % items.length)
    //     }, time)

    //     return () => clearTimeout(id)
    // }, [active])

    if (items.length < 1) {
        return <RandomStub />
    }

    return <ListOut id="_id" items={items} active={0} Component={Slide} /> // active

}

export { Slide }
export default FeaturedSlider