const salekinds = [
    'VAT marża',
    'Faktura VAT',
    'Możliwość finansowania',
    'Leasing',
    'Oferty prywatne',
    'Ogłoszenia autoryzowanego dealera',
    'Oferty firmowe',
    'Cesja',
]

export const salekindNames = [
    'vat-marza',
    'faktura-vat',
    'finansowanie',
    'leasing',
    'oferty-prywatne',
    'autoryzowany-dealer',
    'oferty-firmowe',
    'cesja',
]

export default salekinds