import colors, { colorNames } from '@constants/values/colors'
import lacquers, { lacquerNames } from '@constants/values/lacquers'

const nadwozieOsobowy = { t: 'Nadwozie', inputs: [
    { t: 'Kolor', type: 'multi-checkbox', name: 'kolor', options: colors, names: colorNames },
    { t: 'Rodzaj lakieru', type: 'all-checkbox', name: 'lakier', options: lacquers, names: lacquerNames },
    { t: 'Dopuszczalna ładowność', type: 'range', name: 'dopuszczalna-ladownosc', unit: 'kg', hint: { url: '/load_capacity' }, small: true },
    { t: 'Dopuszczalna masa całkowita', type: 'range', name: 'dopuszczalna-masa', unit: 'kg', hint: { url: '/total_mass' }, small: true },
    { t: 'Kubatura', type: 'range', name: 'kubatura', unit: 'm³', hint: { url: '/capacity' }},
    { t: 'Liczba miejsc', type: 'range',  name: 'liczba-miejsc', hint: { url: '/seats' }},
]}

export default nadwozieOsobowy