import SearchForm from '@components/search/forms/SearchForm'
import Router from 'next/router'
import { truckFrontpageInputs, truckFormInputs, truckAdvancedInputs,
    truckFormInputsMobile, truckAdvancedInputsMobile } from '@components/search/truckFormInputs'
import { TRUCK_LINK_BASE } from '@constants/searchFormLinks'
import paliwo from '@components/search/inputs/paliwo'
import searchFormLink from '@utils/links/searchFormLink'

import styles from 'styles/FormWrapperBanner.module.css'

const CarFrontpageForm = ({ overrideDefaultValues, withBanner }) => {
    const navigateToSearchPage = (data) => {
        const link = searchFormLink({
            data,
            linkBase: TRUCK_LINK_BASE,
            isMakeModelBase: true
        })

        Router.push(link)
    }

    return <SearchForm
        inputs={(withBanner ? truckFrontpageInputs : truckFormInputs)}
        mobileInputs={truckFormInputsMobile}
        dataKey="frontpage"
        overrideDefaultValues={overrideDefaultValues}
        onInit={purgeSessionStorageKeysStartingWith('form-')}
        onSubmit={navigateToSearchPage}
        actionBar={{
            advancedOptions: truckAdvancedInputs,
            advancedOptionsMobile: truckAdvancedInputsMobile,
            allOptions: !withBanner,
            allOptionsAsInput: withBanner,
            clear: !withBanner,
            paliwo,
        }}
        styles={withBanner ? styles : undefined} />
}

export default CarFrontpageForm

const emptyValue = JSON.stringify({})
function purgeSessionStorageKeysStartingWith (phrase) {
    return function purge () {
        try {
            const entries = Object.keys(sessionStorage)
            for (const key of entries) {
                if (key.startsWith(phrase)) {
                    sessionStorage.setItem(key, emptyValue)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
}