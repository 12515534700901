export default function searchFromObject (data, mapValue) {
    const string = Object.entries(data)
        .reduce((acc, pair) => {
            const [key, val] = pair

            const include = key && val && val !== 'Wszystkie'
            if (!include) return acc

            const isCheckbox = typeof val === 'boolean' || val === 'on'
            const isArray = Array.isArray(val)

            let finalValue

            const valueMapping = mapValue && mapValue[key]
            const mappedValue = valueMapping && val
                ? valueMapping[val] || val
                : val

            if (isCheckbox) {
                finalValue = `${encode(key)}=true`
            } else if (isArray) {
                finalValue = `${encode(key)}=${encodeArray(mappedValue)}`
            } else {
                finalValue = `${encode(key)}=${encode(mappedValue)}`
            }

            acc.push(finalValue)
            return acc

        }, [])
        .join('&')

    return string.length > 0
        ? `?${string}`
        : ''
}

function encode (val) {
    return encodeURIComponent(val)
}

function encodeArray (arr) {
    return arr.map(val => encode(val)).join(',')
}