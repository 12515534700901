import encodeForUrl from './encodeForUrl'
import offerSlug from './offerSlug'
import resolveLinkBase from './offerResolveLinkBase'

const offerLink = (offer) => {
    const linkBase = resolveLinkBase(offer)

    if (!linkBase) return '/'

    const tracking = offer.tracking || {}
    const price = offer.price || {}

    const id = offer._shortid
    const lang = tracking.lang && tracking.lang.toLowerCase() || 'pl'
    const city = encodeForUrl(tracking.city || 'bez-lokalizacji')
    const slug = encodeForUrl(offerSlug(offer) || 'bez-tytulu')

    const isRent = price.rent
    if (isRent) {
        return [linkBase, slug, id, lang].join('/')
    }

    const isMakeModelBased = Boolean(offer.make && !linkBase.startsWith('/akcesoria'))
    if (isMakeModelBased) {
        const make = encodeForUrl(offer.make || 'bez-marki')
        const model = encodeForUrl(offer.model || 'bez-modelu')

        return [linkBase, make, model, city, slug, id, lang].join('/')

    } else {
        return [linkBase, slug, id, lang].join('/')
    }
}

export default offerLink