import { useState } from 'react'
import Image from 'next/image'
import Spinner from '@components/spinner/SpinnerBlock'

const ImageWithFallback = ({ src, fallback = '/fallback/list.png',
    customFallbackResolver, ...rest }) => {

    const wasPreloaded = Boolean(rest?.priority)
    const [loading, setLoading] = useState(!wasPreloaded)
    const [fallbackSrc, setFallbackSrc] = useState(null)

    return (
        <>
            { loading && <Spinner small /> }

            <Image {...rest}

                src={fallbackSrc || src}

                onLoadingComplete={() => {
                    setLoading(false)
                }}

                onError={() => {
                    const replacementImg =
                        (customFallbackResolver && customFallbackResolver()) || fallback

                    if (replacementImg) setFallbackSrc(replacementImg)
                }}
            />
        </>
    )
}

export default ImageWithFallback