const FUEL = [
    'Benzyna',
    'Diesel',
    'Hybryda',
    'Elektryczne',
    'Gaz',
]

const FUEL_MOTOR = [
    'Benzyna',
    'Diesel',
    'Elektryczne',
]

const FUEL_CAPTIONS = {
    'Elektro': 'Elektryczne',
}

export {
    FUEL,
    FUEL_CAPTIONS,
    FUEL_MOTOR,
}