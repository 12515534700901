import FormLinks from './FormLinks'
import FormLinkDropdown from './FormLinkDropdown'
import useWindowWidth from '@hooks/useWindowWidth'
import isEmpty from '@utils/object/isEmpty'
import withMobileSearchFormButtons from '@hoc/withMobileSearchFormButtons'
import mix from '@utils/styles/mix'
import ChangingAdBanner from '@components/frontpage/ChangingAdBanner'

import styles from 'styles/FormWrapperBanner.module.css'

const FormWrapperBanner = ({
        banners,
        Filters,
        Form,
        formLinks,
        linksOnTop,
        showFilters = true,
        showForm = true,
        ...rest
    }) => {

    const formStyle = (showForm || showFilters === false)
        ? undefined
        : {display:'none'}

    const width = useWindowWidth()
    const withBanner = Boolean(Array.isArray(banners) && banners.length) && width > 1150

    return (
        <div className={mix([styles.wrapper, withBanner && styles.with_banner])}>
            <div className={linksOnTop
                ? styles.search_links_on_top
                : styles.search}>

                <FormPicker links={formLinks} linksOnTop={linksOnTop} width={width} {...rest} />
                { showFilters && Filters }
                <div style={formStyle}>
                    <Form overrideDefaultValues={isEmpty(rest?.overrideDefaultValues)
                        ? undefined
                        : rest.overrideDefaultValues}

                        styles={styles}
                        withBanner={withBanner} />
                </div>
            </div>

            { withBanner && <div className={styles.banner}>
                <ChangingAdBanner data={banners} style={{height: 400}} />
            </div> }
        </div>
    )
}

function FormPicker ({ links, linksOnTop, width, ...rest }) {
    const { formLinks, dropdownLinks } = divideOnDesktop(links, width, linksOnTop)

    return (
        <aside className={styles.formpicker}>
            <FormLinks links={formLinks} {...rest} />
            { dropdownLinks &&
                <FormLinkDropdown
                    active={rest.active - formLinks.length}
                    dropdownLinks={dropdownLinks} /> }
        </aside>
    )
}

function divideOnDesktop (links, width, linksOnTop) {
    if (width > 1150 || width === 0) {
        return linksOnTop
            ? {
                formLinks: links.slice(0, 8),
                dropdownLinks: links.slice(8),
              }
            : {
                formLinks: links.slice(0, 5),
                dropdownLinks: links.slice(5),
              }
    } else {
        return {
            formLinks: links,
            dropdownLinks: null,
        }
    }

}

export default withMobileSearchFormButtons(FormWrapperBanner)