const drives = [
    'Na przednie koła',
    'Na tylne koła',
    '4x4',
]

export const driveNames = [
    'naped-przednie-kola',
    'naped-tylne-kola',
    'naped-4x4',
]

export default drives