export const CAR_LINK_BASE = '/samochody-osobowe'
export const TRUCK_LINK_BASE = '/samochody-dostawcze'
export const MOTORCYCLE_LINK_BASE = '/motocykle'
export const PART_LINK_BASE = '/czesci-auta-dostawcze'
export const ACCESSORY_LINK_BASE = '/akcesoria-samochody-dostawcze'
export const SERVICE_LINK_BASE = '/serwis-aut-dostawczych'
export const TOOL_LINK_BASE = '/narzedzia-warsztatowe-wyposazenie'
export const AGRI_LINK_BASE = '/maszyny-rolnicze-sprzedaz'
export const CONSTRUCTION_LINK_BASE = '/maszyny-budowlane-sprzedaz'
export const TRAILER_LINK_BASE = '/przyczepy'
export const HEAVY_LINK_BASE = '/samochody-ciezarowe'

export const RENT_LINK_BASE = '/wynajem'

export const CAR_RENT_LINK_BASE = RENT_LINK_BASE + CAR_LINK_BASE
export const TRUCK_RENT_LINK_BASE = RENT_LINK_BASE + TRUCK_LINK_BASE
export const MOTORCYCLE_RENT_LINK_BASE = RENT_LINK_BASE + MOTORCYCLE_LINK_BASE
export const AGRI_RENT_LINK_BASE = RENT_LINK_BASE + '/maszyny-rolnicze'
export const TRAILER_RENT_LINK_BASE = RENT_LINK_BASE + TRAILER_LINK_BASE

export const BUY_LINK_BASE = '/kupie'
export const BUY_LINK_BASE_2 = '/kupie'

export const BUY_CAR_LINK_BASE = BUY_LINK_BASE + '/osobowe'
export const BUY_TRUCK_LINK_BASE = BUY_LINK_BASE + TRUCK_LINK_BASE
export const BUY_MOTORCYCLE_LINK_BASE = BUY_LINK_BASE + MOTORCYCLE_LINK_BASE
export const BUY_PART_LINK_BASE = BUY_LINK_BASE + PART_LINK_BASE
export const BUY_CONSTRUCTION_LINK_BASE = BUY_LINK_BASE + '/maszyny-budowlane'
export const BUY_TRAILER_LINK_BASE = BUY_LINK_BASE + TRAILER_LINK_BASE
export const BUY_TOOL_LINK_BASE = BUY_LINK_BASE_2 + '/narzedzia-warsztatowe'
export const BUY_AGRI_LINK_BASE = BUY_LINK_BASE_2 + '/maszyny-rolnicze'
// ...