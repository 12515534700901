const format = (amount, separator = '.', forceDecimals = false) => {
    if (isNaN(amount) || !amount) return 0

    const price = (amount !== parseInt(amount) || forceDecimals)
        ? amount.toFixed(2)
        : String(amount)

    return price
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, ('$1' + separator))
}

export default format