const additionalStates = [
    'Zarejestrowany w Polsce',
    'Posiada nr. rejestracyjny',
    'Historia serwisu w ASO',
    'Pierwszy właściciel',
    'Podany VIN',
    'Zarejestrowany jako zabytek',
    'Homologacja ciężarowa',
]

export const additionalStatesNames = [
    'zarejestrowane-w-polsce',
    'z-numerem-rejestracyjnym',
    'z-historia-aso',
    'pierwszy-wlasciciel',
    'z-vin',
    'zabytkowe',
    'z-homologacja-ciezarowa',
]

export default additionalStates