import marka from '@components/search/inputs/markaTruck'
import model from '@components/search/inputs/modelTruck'
import typ from '@components/search/inputs/typTruck'
// import typMulti from '@components/search/inputs/typTruckMultiple'
import cena from '@components/search/inputs/cena'
import rata from '@components/search/inputs/rata'
import stan from '@components/search/inputs/stan'
import przebieg from '@components/search/inputs/przebieg'
import rocznik from '@components/search/inputs/rocznik'
import lokalizacja from '@components/search/inputs/lokalizacjaZasieg'
import szukaj from '@components/search/inputs/szukaj'

import krajPochodzenia from '@components/search/inputsAdvanced/krajPochodzenia'
import stanPojazdu from '@components/search/inputsAdvanced/stanPojazdu'
import silnikNaped from '@components/search/inputsAdvanced/silnikNapedSamochody'
import rodzajSprzedazy from '@components/search/inputsAdvanced/rodzajSprzedazy'
import nadwozie from '@components/search/inputsAdvanced/nadwozieDostawczy'
import dodatkoweWyposazenie from '@components/search/inputsAdvanced/dodatkoweWyposazenieSamochody'

const truckFrontpageInputs = [
    stan,
    typ,

    marka,
    model,

    rocznik,
    rata,

    przebieg,
    cena,

    lokalizacja,
]

const truckFormInputs = [
    marka,
    cena,
    stan,

    model,
    rata,
    przebieg,

    typ,
    rocznik,
    lokalizacja,
]

const truckAdvancedInputs = [
    krajPochodzenia,
    stanPojazdu,
    silnikNaped,
    rodzajSprzedazy,
    nadwozie,
    dodatkoweWyposazenie,
]

const truckFormInputsMobile = [
    marka,
    model,
    typ,
    rocznik,
    cena,
    { type: 'blank' },
]

const truckAdvancedInputsMobile = [
    lokalizacja,
    stan,
    szukaj,
    przebieg,
    ...truckAdvancedInputs,
]

export {
    truckFrontpageInputs,
    truckFormInputs,
    truckAdvancedInputs,
    truckFormInputsMobile,
    truckAdvancedInputsMobile,
}