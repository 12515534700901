import pojemnoscSkokowa from '@components/search/inputs/pojemnoscSkokowa'
import mocSilnika from '@components/search/inputs/mocSilnika'
import gearboxes, { gearboxNames } from '@constants/values/gearboxes'
import drives, { driveNames } from '@constants/values/drives'

const silnikNaped = { t: 'Silnik i napęd', inputs: [
    pojemnoscSkokowa,
    mocSilnika,
    { t: 'Skrzynia biegów', type: 'all-checkbox', name: 'skrzynia-biegow', options: gearboxes, names: gearboxNames },
    { t: 'Napęd', type: 'all-checkbox', name: 'naped', options: drives, names: driveNames },
    { t: 'Kierownica po prawej stronie', type: 'all-checkbox', name: 'kierownica',
        options: ['Tak', 'Nie'], names: ['kierownica-po-prawej', 'kierownica-po-lewej']}
]}

export default silnikNaped