import FormWrapperBanner from './FormWrapperBanner'
import TruckFrontpageForm from './TruckFrontpageForm'
import { frontpageLinks } from '../formLinks'

const FrontpageForm = ({ banners }) => {
    return (
        <FormWrapperBanner
            banners={banners}
            Form={TruckFrontpageForm}
            formLinks={frontpageLinks}
            showFilters={false}
        />
    )
}

export default FrontpageForm